import React from "react";

// reactstrap components
import { Button, Container,Modal,   InputGroupAddon,
  InputGroupText, Input,
  InputGroup,  FormGroup,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Row, Col
} from "reactstrap";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-google-places-autocomplete';
// core components

function LandingPageHeader(props) {
  let pageHeader = React.createRef();
  const [classic, setClassic] = React.useState(false);
  const [selectedService, setSelectedService] = React.useState();
  const [address, setAddress] = React.useState();
  const [addressLatLng, setAddressLatLng] = React.useState();
  const [addressLocation, setAddressLocation] = React.useState();
  const [phone, setPhone] = React.useState();
  const [name, setName] = React.useState();
  const [interestedServices, setInterestedServices] = React.useState();

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });
  const getCoordinates = (address) => {
    geocodeByAddress(address.value.description)
      .then(results => getLatLng(results[0]))
      .then((data) => {
        // console.log('LAT: ', data.lat);
        // console.log('LNG: ', data.lng);
        setAddress(address.value.description)
        if (address.value && address.value.structured_formatting && address.value.structured_formatting.secondary_text) {
          setAddressLocation(address.value.structured_formatting.secondary_text)
        }
        setAddressLatLng(data)
      })
  };
  const finalizeSchedule = () =>{
    setClassic(false)
  }

  return (
    <>
      <div
        className="page-header"
        ref={pageHeader}
        style={{
          backgroundImage:
            "url(" +
            require("assets/img/onroad-laundry.jpeg").default +
            ")",
        }}
      >
        <Modal
                isOpen={classic}
                toggle={() => setClassic(false)}
                className="modal-register"
              >
                <div className="modal-header no-border-header text-center">
                  <button
                    className="close"
                    type="button"
                    onClick={() => setClassic(false)}
                  >
                    <span>×</span>
                  </button>
                  <h6 className="text-muted">Schedule A Pickup</h6>
                </div>
                <div className="modal-body">
                  <FormGroup>
                    <label>Your Address</label>
                    <GooglePlacesAutocomplete
                      selectProps={{
                      onChange: (address) => getCoordinates(address),
                    }}
                      placeholder="Search Address "
                      apiKey = "AIzaSyBqFuNz0gvqEjI0ymFGFX6r3Vwpir91dVk"
                    />
                    {/* <Input defaultValue="" placeholder="Address" type="text" /> */}
                  </FormGroup>
                  <FormGroup>
                    
                    {/* <Input defaultValue="" placeholder="Service Interested" type="text" /> */}
                 
                  </FormGroup>
                  <FormGroup>
                    <label>Phone</label>
                    <Input defaultValue="" placeholder="Phone" type="text" onBlur={(e)=>{
                      setPhone(e.target.value)
                    }}/>
                  </FormGroup>
                  <FormGroup>
                    <label>Name</label>
                    <Input defaultValue="" placeholder="Full name" type="text" onBlur={(e)=>{
                      setName(e.target.value)
                    }}/>
                  </FormGroup>
                  <FormGroup>
                    <label>Service Interested </label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <b>{selectedService}</b>
                  <UncontrolledDropdown  style={{display: 'inline'}}>
                    <DropdownToggle
                      className="btn-just-icon"
                      color="link"
                      size="lg"
                      caret
                    >
                    </DropdownToggle>
                    <DropdownMenu >
                      {props.serviceList && props.serviceList.map((item)=> {
                        return(
                          <DropdownItem>
                        <a
                          key={item.name}
                          color="black"
                          href="#pablo"
                          value={item}
                          onClick={e => {
                            setSelectedService(item.name)}}
                        >
                          <Row>
                            <Col sm="9">{item.name}</Col>
                          </Row>
                        </a>
                      </DropdownItem>
                        )
                      })}
                      
                      <DropdownItem divider />
                    </DropdownMenu>
                    </UncontrolledDropdown>
                    </FormGroup>
                    <div style={{textAlign: "center"}}>
                    <small >Not accepting pickups now</small>
                    </div>
                  <Button block className="btn-round" color="danger" onClick={() => finalizeSchedule()} disabled>
                    Finalize Schedule
                  </Button>
                </div>
              </Modal>
        <div className="filter" />
        <div className="content-center">
          <Container>
            <div className="motto">
              <h1 className="title">{props.name}</h1>
              <h3 className="description">
                Best in the town
              </h3>
              <br />
              <Button
                className="btn-round mr-1"
                color="neutral"
                onClick={() => window.open(`tel:${props.phone[0]?.phone}`)}
              >
                <i className="fa fa-phone" />
                Call
              </Button>
              <Button
                className="btn-round"
                color="neutral"
                type="button"
                outline
                onClick={() => setClassic(true)}
              >
                Schedule A Pickup
              </Button>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default LandingPageHeader;
